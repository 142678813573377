import { localize } from '@/utils/i18n'
export const historyData = [
    {
        year: 2024,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'В&nbsp;рамках процесса реструктуризации в&nbsp;сентябре 2024 года была завершена регистрация в&nbsp;России холдинговой компании МКАО &laquo;ЦИАН&raquo;',
                    en: 'In&nbsp;September 2024, the holding company MKAO CIAN was registered in&nbsp;Russia as&nbsp;part of&nbsp;the restructuring process'
                })
            },
            {
                description: localize({
                    ru: 'Циан запустил умного помощника с&nbsp;применением технологий искусственного интеллекта ЦианGPT',
                    en: 'Cian launched a&nbsp;smart assistant powered by&nbsp;AI&nbsp;— CianGPT'
                })
            },
        ]
    },
    {
        year: 2023,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Приобретение SmartDeal&nbsp;— одного из&nbsp;ведущих игроков на&nbsp;рынке электронной регистрации сделок с&nbsp;недвижимостью в&nbsp;России',
                    en: 'The acquisition of SmartDeal (one of the leading players on the Russian real estate electronic registration market)'
                })
            },
        ]
    },
    {
        year: 2022,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Циан запустил возможность онлайн-бронирования посуточной аренды&nbsp;&mdash; Циан.Посуточная.',
                    en: 'Cian relaunched its service for daily rentals&nbsp;&mdash; Cian.Daily.'
                })
            },
            {
                description: localize({
                    ru: 'Циан первым среди классифайдов провел первую межконтинентальную сделку купли-продажи онлайн.',
                    en: 'Cian became the first real estate classified to&nbsp;conduct an&nbsp;intercontinental online transaction.'
                })
            }
        ]
    },
    {
        year: 2021,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Циан первым в&nbsp;России запустил сервис по&nbsp;проведению сделки онлайн на&nbsp;вторичном рынке без привязки к&nbsp;отделению банка&nbsp;&mdash; Циан.Сделка.',
                    en: 'Cian became the first in&nbsp;Russia to&nbsp;launch an&nbsp;online transaction service for the secondary real estate market without being tied to&nbsp;a&nbsp;bank branch&nbsp;&mdash; Cian.Transaction.'
                })
            },
            {
                description: localize({
                    ru: 'Циан вышел на&nbsp;IPO и&nbsp;акции компании стали торговаться на&nbsp;Нью-Йоркской (NYSE) и&nbsp;на&nbsp;Московской биржах под торговым кодом CIAN.',
                    en: 'Cian went public and its ADRs began trading on&nbsp;the New York (NYSE) and Moscow (MOEX) stock exchanges (ticker: CIAN).'
                })
            },
            {
                description: localize({
                    ru: 'Циан запускает сервис для профессионалов рынка коммерческой недвижимости&nbsp;&mdash; CRE.Space.',
                    en: 'Cian launced a&nbsp;service for commercial real estate market professionals&nbsp;&mdash; CRE.Space.'
                })
            }
        ]
    },
    {
        year: 2020,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Циан стал бесплатным для всех пользователей России на&nbsp;время самоизоляции в&nbsp;рамках антикризисных мер поддержки для риелторов в&nbsp;период пандемии коронавируса.',
                    en: 'Cian became free for all users in&nbsp;Russia for the duration of&nbsp;the self-isolation as&nbsp;part of&nbsp;anti-crisis support measures for real estate agents during the COVID-19&nbsp;pandemic.'
                })
            },
            {
                description: localize({
                    ru: 'Циан запустил новый сервис&nbsp;&mdash; Циан.Коворкинги.',
                    en: 'Launched a&nbsp;new service&nbsp;&mdash; Cian.Co-working.'
                })
            },
            {
                description: localize({
                    ru: 'Циан подписал соглашение о&nbsp;приобретении одного из&nbsp;лидирующих региональных порталов-классифайдов по&nbsp;недвижимости&nbsp;&mdash; N1.ru.',
                    en: 'Cian signed an&nbsp;agreement to&nbsp;acquire one of&nbsp;the leading regional real estate portals&nbsp;&mdash; N1.ru.'
                })
            }
        ]
    },
    {
        year: 2019,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Запуск сервиса Циан.Ипотека, с&nbsp;помощью которого стало возможным получить предложения от&nbsp;нескольких банков за&nbsp;2&nbsp;минуты.',
                    en: 'Launched Cian.Mortgage allowing users to&nbsp;get offers from several banks in&nbsp;2&nbsp;minutes.'
                })
            }
        ]
    },
    {
        year: 2018,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Циан стал федеральным ресурсом, охватил все регионы&nbsp;РФ и&nbsp;запустил первую федеральную рекламную кампанию.',
                    en: 'Cian became a&nbsp;federal resource, covered all regions of&nbsp;the Russian Federation and launched its first federal advertising campaign.'
                })
            }
        ]
    },
    {
        year: 2017,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Запуск Циан.Журнала&nbsp;&mdash; преемника сайта Realty.dmir.ru.',
                    en: 'Launched Cian.Magazine&nbsp;&mdash; a&nbsp;successor of&nbsp;Realty.dmir.ru website.'
                })
            }
        ]
    },
    {
        year: 2016,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Запуск сервиса Циан.Оценка, с&nbsp;помощью которого можно рассчитать рыночную стоимость квартиры. Это помогает определить справедливую цену для продажи или покупки.',
                    en: 'Launched Cian.Valuation service helping users of&nbsp;our platform to&nbsp;calculate the appartments market value.'
                })
            }
        ]
    },
    {
        year: 2016,
        period: '2015-2016',
        events: [
            {
                description: localize({
                    ru: 'Циан вышел в&nbsp;города-миллионники: Самару, Нижний Новгород, Краснодар, Уфу, Ростов-на-Дону, Казань.',
                    en: 'Cian entered million-plus cities: Samara, Nizhny Novgorod, Krasnodar, Ufa, Rostov-on-Don, Kazan.'
                })
            }
        ]
    },
    {
        year: 2015,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Запущена первая масштабная рекламная кампания Циан на&nbsp;ТВ и&nbsp;в&nbsp;интернете под слоганом &laquo;Миллионы людей уже нашли жилье на&nbsp;Cian.ru&raquo;.',
                    en: 'Launched the first large-scale advertising campaign on&nbsp;TV and the Internet under the slogan &quot;Millions of&nbsp;people have already found a&nbsp;home on&nbsp;Cian.ru&quot;.'
                })
            },
            {
                description: localize({
                    ru: 'Один из&nbsp;рекламных роликов Циан стал вирусным и&nbsp;собрал более 22&nbsp;млн. просмотров на&nbsp;YouTube и&nbsp;в&nbsp;социальных сетях.',
                    en: 'One of&nbsp;Cians advertising videos became viral and gathered more than 22&nbsp;million views on&nbsp;Youtube and social networks.'
                })
            }
        ]
    },
    {
        year: 2014,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'У&nbsp;Циан появилась клиентская служба.',
                    en: 'Cian launched a&nbsp;customer service.'
                })
            },
            {
                description: localize({
                    ru: 'Выход на&nbsp;рынок СПб за&nbsp;счет приобретения сайта EMLS.ru.',
                    en: 'Entered the St. Petersburg market by&nbsp;acquiring EMLS.ru.'
                })
            },
            {
                description: localize({
                    ru: 'Циан полностью обновил дизайн и&nbsp;навигацию сайта и&nbsp;запустил мобильное приложение Циан.',
                    en: 'Cian completely redesigned the website and launched the mobile app.'
                })
            }
        ]
    },
    {
        year: 2013,
        period: '2013-2011',
        events: [
            {
                description: localize({
                    ru: 'Происходило наращивание контента и&nbsp;выручки.',
                    en: 'Growth of&nbsp;content and revenue.'
                })
            }
        ]
    },
    {
        year: 2007,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Появился раздел Продажа. Одновременно с&nbsp;этим Циан начал бороться за&nbsp;качество контента и&nbsp;сделал первые шаги по&nbsp;монетизации раздела. Появился формат премиум-объявлений и&nbsp;строгое ограничение по&nbsp;времени актуальности объявления.',
                    en: 'Launced sale segment. Cian started improving the quality of&nbsp;content and made the first steps towards monetization of&nbsp;the segment. Introduced premium lisiting and a&nbsp;strict time limit for listing relevancy.'
                })
            }
        ]
    },
    {
        year: 2006,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Появилась аренда коммерческой недвижимости. Через год этот сегмент был монетизирован.',
                    en: 'Launched commercial real estate segment. The segment was monetized later that year.'
                })
            }
        ]
    },
    {
        year: 2005,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Циан стал платным. Появилась возможность публиковать объявления на&nbsp;длительный срок (ранее срок размещения был ограничен одним днем).',
                    en: 'Cian became a&nbsp;paid service. Launched long-term listings (previously the listings lifespan was limited by&nbsp;a&nbsp;day).'
                })
            },
            {
                description: localize({
                    ru: 'Доработали фильтры, был построен первый модерационный робот.',
                    en: 'Improved filters, launched the first moderation robot.'
                })
            }
        ]
    },
    {
        year: 2003,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Циан объединился с&nbsp;доской объявлений &laquo;Недвижимость и&nbsp;Цены&raquo;. База данных стала общей, а&nbsp;работа продуктивнее. Ежедневно на&nbsp;Циан публиковалось порядка 1000-1500 объявлений.',
                    en: 'Cian merged with &quot;Real Estate and Prices&quot; ad&nbsp;service increasing the database and optimizing processes. Between&nbsp;1,000 and 1,500 listings were being published every day.'
                })
            },
            {
                description: localize({
                    ru: 'Циан стал целенаправленно привлекать пользователей, которые ищут себе недвижимость, и&nbsp;рассказывать людям, как эффективно найти квартиру самостоятельно. Появились первые фильтры, поиск стал более эффективным и&nbsp;удобным.',
                    en: 'Cian began to&nbsp;deliberately attract seekers (those looking for real estate) and tell people how to&nbsp;effectively find an&nbsp;apartment on&nbsp;their own. Launched filters appeared; the search became more effective and convenient.'
                })
            }
        ]
    },
    {
        year: 2001,
        period: null,
        events: [
            {
                description: localize({
                    ru: 'Циан начинал как эксель-таблица, которая актуализировалась каждый день вручную агентами. Циан работал только в&nbsp;Москве и&nbsp;только в&nbsp;сегменте Аренда.',
                    en: 'Cian.ru was launched as&nbsp;a&nbsp;manually updated spreadsheet regularly updated on&nbsp;the website. Cian covered covered only rent segment of&nbsp;Moscow.'
                })
            }
        ]
    }
]
