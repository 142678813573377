<template>
    <div class="navigation-mobile-body">
        <div class="navigation-mobile-body__submenu">
            <div class="navigation-mobile-body__submenu-wrapper container">
                <ul class="navigation-mobile-body__list">
                    <li v-if="currentLevel.parent" class="navigation-mobile-body__item navigation-mobile-body__item--parent">
                        <component
                            :is="currentLevel.parent.pageParams.redirect ? 'span' : 'a'"  :href="currentLevel.parent.link"
                            v-html="currentLevel.parent.name"
                            class="navigation-mobile-body__item-inner"
                            :class="{'navigation-mobile-body__item-inner--redirect' : currentLevel.parent.pageParams.redirect}"
                        />
                    </li>
                    <li class="navigation-mobile-body__item"
                        v-for="(item, index) in currentLevel.items"
                        :key="index"
                        :class="[{'is-active': checkActiveState(item)}, {'navigation-mobile-body__item--submenu': levelCount !== 0}]">
                        <template v-if="item.items && item.items.length">
                            <template v-if="showAlternateLink">
                                <accordion-link :arrow-icon="icons.link" :item-parent="item">
                                    <span class="navigation-mobile-link" v-html="item.name" />
                                </accordion-link>
                            </template>
                            <span v-else class="navigation-mobile-body__item-inner" @click="changeLevel(item, header)">
                                <span v-html="item.name"/>
                                <z-icon
                                    class="navigation-mobile-body__arrow navigation-mobile-body__arrow--right"
                                    :name="icons.link"
                                    dir="right"
                                />
                            </span>
                        </template>
                        <a v-else
                           class="navigation-mobile-link"
                           :href="item.link"
                           :title="item.name"
                           :target="item.params && item.params.target ? item.params.target : '_self'"
                        >
                            <span>
                                <span v-html="item.name" />
                                <z-icon
                                    class="navigation-mobile-body__external-icon"
                                    v-if="item.params.EXTERNAL"
                                    :name="icons.external"
                                />
                            </span>
                        </a>
                    </li>
                </ul>
                <div class="navigation-mobile-body__footer">
                    <stock-ticker />
                    <div class="navigation-mobile-body__wrapper">
                        <div class="navigation-mobile-body__social">
                            <z-link href="https://t.me/cian_ir" target="_blank">
                                <z-icon
                                    name="social/telegram-s"
                                    height="24"
                                    width="24"
                                ></z-icon>
                            </z-link>
                            <z-link href="https://www.tbank.ru/invest/social/profile/Cian_IR/" target="_blank">
                                <z-icon
                                    name="social/tinkoff-pulse"
                                    height="24"
                                    width="24"
                                ></z-icon>
                            </z-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccordionLink from './AccordionLink.vue'
import { localize } from '@/utils/i18n'

export default {
    name: 'navigation-mobile-body',
    components: {
        AccordionLink
    },
    props: {
        icons: {
            type: Object,
            required: true
        },
        data: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            lang: this.$root.lang,
            text: {
                back: 'В главное меню',
                subscribe: localize({
                    ru: 'Подписаться',
                    en: 'Subscribe'
                })
            },
            levelMap: [],
            levelCount: 0,
            currentLevel: {
                items: [],
                header: null,
                parent: null
            }
        }
    },
    mounted () {
        this.levelMap.push({
            items: this.data,
            header: null,
            parent: null
        })
        this.updateCurrentLevel()
    },
    computed: {
        header () {
            if (this.currentLevel.parent === null) {
                return 'Главная страница'
            }
            return this.currentLevel.parent.name
        },
        maxLevels () {
            const collection = new Set()
            this.levelsCounter(this.data, collection)
            return Math.max(...collection)
        },
        showAlternateLink () {
            return this.levelCount === this.maxLevels - 2
        }
    },
    methods: {
        changeLevel (item, header) {
            this.levelCount = item.level
            this.levelMap.push(this.adaptDataToLevel(item, header))
            this.updateCurrentLevel()
        },
        backToLevel () {
            this.levelCount -= 1
            this.levelMap.splice(this.levelMap.length - 1, 1)
            this.updateCurrentLevel()
        },
        checkActiveState (item) {
            if (item.active === true) return true

            for (let i = 0; i < item.items?.length; i++) {
                if (item.items[i].active === true) return true
            }

            return false
        },
        // levelsCounter считает рекрсивно сколько у нас всего уровней
        levelsCounter (parent, collection) {
            parent.forEach((item) => {
                collection.add(item.level)
                item.items?.length > 0 && (this.levelsCounter(item.items, collection))
            })
        },
        adaptDataToLevel (data, header) {
            return {
                items: data.items,
                header: header,
                parent: data
            }
        },
        /* В связи с тем что watch и computed не могут отследить изменение
        массива пришлось делать отдельный метод для обновления текущего уровня
        вложенности */
        updateCurrentLevel () {
            this.currentLevel = this.levelMap[this.levelMap.length - 1]
        }
    }
}
</script>


<style lang="scss">
@import "../vars";

.navigation-mobile-body {
    width: 100%;
    flex-grow: 1;
    padding: $token-spacers-xs 0;

    &__header {
        background-color: $token-colors-black-5;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: $token-spacers-xs 0;
    }

    &__search {
        background-color: $token-colors-white;
    }

    &__submenu {
        height: 100%;
    }

    &__submenu-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__footer {
        border-top: 1px solid $token-colors-black-10;
        padding-top: $token-spacers-2-xl;
        margin-top: auto;
    }

    &__wrapper {
        margin-top: $token-spacers-xs;
    }

    &__wrapper, &__social {
        display: flex;
        align-items: center;
        gap: $token-spacers-xs;
    }

    &__arrow {
        height: 24px;
        width: 24px;

        &--right {
            margin-left: 0.25em;
        }

        &--left {
            margin-right: 0.25em;
        }
    }

    &__list {
        padding: 0;
        margin: 0;

        &--top {
            padding: 1rem 0;
        }
    }

    &__item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        line-height: 1.2;
        @include typo-level(2XL);
        font-weight: 500;
        margin-bottom: $token-spacers-3-xs;

        &:last-child {
            margin-bottom: $token-spacers-xs;
        }

        .navigation-mobile-body__item-inner {
            color: $nav-link-color;
        }

        &.is-active {
            span,
            .navigation-mobile-body__item-inner {
                color: $nav-link-color-active;

                .z-icon {
                    path {
                        fill: $nav-link-color-active;
                    }
                }
            }
        }

        &--parent {
            .navigation-mobile-body__item-inner {
                color: $nav-parent-link-color;
                @include typo-level(M);
                border-bottom: 1px solid transparent;
                padding-bottom: 0;
            }
        }

        &--submenu {
            .navigation-mobile-body__item-inner {
                padding-left: $token-spacers-2-xs;
            }
        }
    }

    &__item-inner {
        flex: 0 0 100%;
        max-width: 100%;
        font-weight: 600;
        @include typo-level(S);
        padding: $token-spacers-xs 0;
        color: $nav-link-color;
        border-bottom: 1px solid $token-colors-black-10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .z-icon {
            path {
                fill: $nav-link-color;
            }
        }

        .navigation-mobile-body__external-icon {
            path {
                fill: $nav-external-icon-color;
            }
        }

        &--redirect {
            cursor: auto;
        }
    }

    &__subscribe {
        color: $token-colors-button-bordered-default !important;
    }

    .quotes {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        transition: box-shadow $transition;
        padding: 10px;
        border-radius: 10px;
        text-decoration: none;

        &__title {
            display: block;
            margin-bottom: $token-spacers-2-xs;
            color: $token-colors-black-90;
            font-weight: 500;
            font-size: 18px;
        }

        > span {
            color: $token-colors-black;
            font-size: 18px;
        }

        iframe {
            pointer-events: none;
        }

        &:hover {
            box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        }
    }
}

</style>
